import Chart from "chart.js";
import drawFunction from "../../chart-utils/draw-function";
import chartOptions from "../../chart-utils/chart-options";
import colors from "../../chart-utils/chart-colors";
import parseChartOptions from "../../chart-utils/parse-chart-options";

export default function barChart(el, props) {
  const colorScheme = "dark";

  let data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  // Chart configuration
  parseChartOptions(Chart, chartOptions());
  Chart.elements.Rectangle.prototype.draw = drawFunction;

  let chart = new Chart(el, {
    type: "bar",
    data: data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 10,
              callback: function (value) {
                if (!(value % 10)) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";

            if (data.datasets.length > 1) {
              content +=
                '<span class="popover-body-label mr-auto">' + label + "</span>";
            }

            if (data.datasets.length > 1) {
              content +=
                '<span class="popover-body-value">' + yLabel + "</span>";
            } else {
              content +=
                '<span class="popover-body-value">' +
                yLabel +
                " " +
                label +
                "</span>";
            }

            return content;
          },
        },
      },
    },
  });

  Chart.scaleService.updateScaleDefaults("linear", {
    gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: colors.gray[300],
      drawBorder: false,
      drawTicks: false,
      lineWidth: 0,
      zeroLineWidth: 0,
      zeroLineColor:
        colorScheme == "dark" ? colors.gray[900] : colors.gray[300],
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2],
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback: function (value) {
        if (!(value % 10)) {
          return value;
        }
      },
    },
  });

  // xAxes
  Chart.scaleService.updateScaleDefaults("category", {
    gridLines: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false,
    },
    ticks: {
      padding: 20,
    },
    maxBarThickness: 10,
  });
}
