import accountLogoUpload from "./account-logo-upload";
import accountForm from "./account-form";
import barChart from "./bar-chart";
import barChartHorizontal from "./bar-chart-horizontal";
import clientForm from "./client-form";
import clientLogoUpload from "./client-logo-upload";
import clientReportForm from "./client-report-form";
import coverageItemForm from "./coverage-item-form";
import coverageItemImageUpload from "./coverage-item-image-upload";
import doughnutChart from "./doughnut-chart";
import favouriteButton from "./favourite-button";
import keyMessageForm from "./key-message-form";
import keyPerformanceIndicatorForm from "./key-performance-indicator-form";
import mandatoryForm from "./mandatory-form";
import mediaTierForm from "./media-tiers";
import organisationReportForm from "./organisation-report-form";
import profileImageUpload from "./profile-image-upload";
import publicationAudienceFillModal from "./publication-audience-fill-modal";
import publicReportLinkModal from "./public-report-link-modal";
import quickCoverageItemForm from "./quick-coverage-item-form";
import quickCoverageItemImageUpload from "./quick-coverage-item-image-upload";
import removeImage from "./remove-image";
import reportingPeriodModal from "./reporting-period-modal";

export default {
  accountForm,
  accountLogoUpload,
  barChart,
  barChartHorizontal,
  clientForm,
  clientLogoUpload,
  clientReportForm,
  coverageItemForm,
  coverageItemImageUpload,
  doughnutChart,
  favouriteButton,
  keyMessageForm,
  keyPerformanceIndicatorForm,
  mandatoryForm,
  mediaTierForm,
  organisationReportForm,
  profileImageUpload,
  publicationAudienceFillModal,
  publicReportLinkModal,
  quickCoverageItemForm,
  quickCoverageItemImageUpload,
  removeImage,
  reportingPeriodModal,
};
