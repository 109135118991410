/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails";
import "@rails/request.js";

import domready from "domready";
import viewloader from "viewloader";

import { default as views } from "../views";

import "../vendor/timeago";

let viewloaderManager = viewloader(views);

domready(function onDomready() {
  console.log("domready");
});

document.addEventListener("turbo:load", () => {
  console.log("turbo:load - viewloaderManager.callViews()");
  viewloaderManager.callViews();

  // Timeago - elements auto destroyed when not visible anymore
  $("time[data-time-ago]").timeago();

  // display tooltips
  $('[data-toggle="tooltip"]').tooltip();
});

// DOMContentLoaded equivalent with Turbo
// document.addEventListener('turbo:render', function onTurboLoad () {
// console.log('turbo:render')
// viewloaderManager.callViews()
// })

// FIXME: This is a workaround that hopefully won't be necessary once migrated from viewloader to stimulus
// See: https://github.com/intercom/intercom-rails/issues/336#issuecomment-929072944
document.addEventListener("turbo:submit-end", (event) => {
  window.turboFormSubmissionFailed = !event.detail.success;

  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event("turbo:visit"));
    document.dispatchEvent(new Event("turbo:before-cache"));
  }
});

// FIXME: As above
document.addEventListener("turbo:render", () => {
  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event("turbo:load"));
    window.turboFormSubmissionFailed = false;
  }
});

// Destroy views when navigating
document.addEventListener("turbo:before-render", () => {
  console.log("turbo:before-render - viewloaderManager.destroyViews()");
  viewloaderManager.destroyViews();
});

// forcefully close modals.
// See https://www.honeybadger.io/blog/turbolinks/
document.addEventListener("turbo:before-cache", () => {
  if (document.body.classList.contains("modal-open")) {
    $(".modal").hide().removeAttr("aria-modal").attr("aria-hidden", "true");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  }
});

import "bootstrap";
import "trix/dist/trix.css";

import "../fonts/feather/feather.css";
import "../img/feather-sprite.svg";
import "../img/impact-score-logo.png";
import "../styles/actiontext.scss";
import "../styles/theme.scss";

require("trix");
require("@rails/actiontext");
