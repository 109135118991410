import "select2";

export default function publicationAudienceFillModal(el, props) {
  const searchSelect = $("#publicationSearchSelect");

  function formatSelection(publicationSelection) {
    return publicationSelection.name;
  }

  function formatPublicationResult(result) {
    if (result.loading) {
      return result.text;
    }

    let $container = $(
      "<div class='mb-1 p-2'>" +
        "<h3>" +
        result.name +
        "</h3>" +
        "<div class='mb-2 small'>Audience: " +
        result.audience_size +
        "</div>" +
        "<div class='small'>" +
        result.description +
        "</div>" +
        "<hr>" +
        "</div>",
    );

    return $container;
  }

  searchSelect.on("select2:select", function (e) {
    var data = e.params.data;
    $(el).modal("hide");
    $(".audience-size-field").val(data.audience_size);
  });

  searchSelect.select2({
    ajax: {
      url: props.search_url,
      dataType: "json",
      delay: 250,
    },
    placeholder: "Type to search for a publication",
    templateSelection: formatSelection,
    templateResult: formatPublicationResult,
  });

  function destroy() {
    searchSelect.select2("destroy");
    searchSelect.off("select2:select");
  }

  return {
    destroy: destroy,
  };
}
