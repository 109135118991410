import "select2";

export default function mediaTierForm(el, props) {
  let $el = $(el);

  let select2Tags = $el.find(".select2-tags");
  select2Tags.select2({
    tags: true,
    allowClear: true,
    placeholder: "Choose an option",
  });

  function destroy() {
    select2Tags.select2("destroy");
  }

  return {
    destroy: destroy,
  };
}
