import { Turbo } from "@hotwired/turbo-rails";
import { FetchRequest, post } from "@rails/request.js";

export default function favouriteButton(el, props) {
  const addFavouriteButton = el.querySelector("#add-favourite");
  const removeFavouriteButton = el.querySelector("#remove-favourite");

  function onAddFavouriteButtonClick(e) {
    e.preventDefault();

    // We use request.js rather than ajax() here since it handles CSRF for us
    post(props.path, {
      body: {
        client_id: props.client_id,
      },
      responseKind: "text",
    })
      .then((response) => {
        if (response.ok) {
          Turbo.visit(window.location.toString());
        } else {
          console.log("Error:", response);
        }
      })
      .catch((error) => {
        console.error("Request failed:", error);
      });
  }

  function onRemoveFavouriteButtonClick(e) {
    e.preventDefault();

    // Using the longhand form here since the 'destroy' shorthand method conflicts with the destroy function below
    const request = new FetchRequest(
      "DELETE",
      `${props.path}/${props.favourite_id}`,
      {
        responseKind: "text",
      },
    );

    request
      .perform()
      .then((response) => {
        if (response.ok) {
          Turbo.visit(window.location.toString());
        } else {
          console.log("Error:", response);
        }
      })
      .catch((error) => {
        console.error("Request failed:", error);
      });
  }

  addFavouriteButton.addEventListener("click", onAddFavouriteButtonClick);
  removeFavouriteButton.addEventListener("click", onRemoveFavouriteButtonClick);

  function destroy() {
    addFavouriteButton.removeEventListener("click", onAddFavouriteButtonClick);
    removeFavouriteButton.removeEventListener(
      "click",
      onRemoveFavouriteButtonClick,
    );
  }

  return {
    destroy: destroy,
  };
}
