export default function publicReportLinkModal(el, props) {
  const copyToClipboardButton = el.querySelector(".clipboard-action");
  const secretLinkInput = el.querySelector("#secretLink");

  function onCopyToClipboard(e) {
    e.preventDefault();
    secretLinkInput.select();
    document.execCommand("copy");
  }

  copyToClipboardButton.addEventListener("click", onCopyToClipboard);

  function destroy() {
    copyToClipboardButton.removeEventListener("click", onCopyToClipboard);
  }

  return {
    destroy: destroy,
  };
}
