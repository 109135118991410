import flatpickr from "flatpickr";
import "select2";

export default function clientReportForm(el, props) {
  const periodStart = el.querySelector(".flatpickr-period-start");
  const periodEnd = el.querySelector(".flatpickr-period-end");

  let periodStartInstance = flatpickr(periodStart, {
    altInput: true,
    altFormat: "J F Y",
  });

  let periodEndInstance = flatpickr(periodEnd, {
    altInput: true,
    altFormat: "J F Y",
  });

  function destroy() {
    periodStartInstance.destroy();
    periodEndInstance.destroy();
  }

  return {
    destroy: destroy,
  };
}
