import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import drawFunction from "../../chart-utils/draw-function";
import chartOptions from "../../chart-utils/chart-options";
import colors from "../../chart-utils/chart-colors";
import parseChartOptions from "../../chart-utils/parse-chart-options";

Chart.plugins.unregister(ChartDataLabels);

export default function doughnutChart(el, props) {
  const colorScheme = "dark";

  let data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  // Chart configuration
  parseChartOptions(Chart, chartOptions());

  let chart = new Chart(el, {
    plugins: [ChartDataLabels],
    type: "doughnut",
    data: data,
    options: {
      plugins: {
        datalabels: {
          font: {
            size: 12,
          },
          color: "white",
          formatter: function (value, context) {
            var total = context.chart.data.datasets[0].data.reduce(
              (a, b) => a + b,
              0,
            );

            var percent = 0;

            if (total > 0) {
              percent = (100 * value) / total;
            }

            if (percent === 0) {
              return "";
            }

            return Math.round(percent) + "%";
          },
        },
      },
      tooltips: {
        callbacks: {
          title: function (item, data) {
            var title = data.labels[item[0].index];
            return title;
          },
          label: function (item, data) {
            var value = data.datasets[0].data[item.index];
            var content = "";

            var total = data.datasets[0].data.reduce((a, b) => a + b, 0);

            var percent = 0;

            if (total > 0) {
              percent = (100 * value) / total;
            }

            content +=
              '<span class="popover-body-value">' +
              value +
              " items (" +
              Math.round(percent) +
              "%)</span>";
            return content;
          },
        },
      },
    },
  });

  if (props.legend_selector) {
    const content = chart.generateLegend();
    $(props.legend_selector).html(content);
  }
}
