import flatpickr from "flatpickr";
import "select2";

export default function coverageItemForm(el, props) {
  let $el = $(el);

  const dateField = el.querySelector(".flatpickr-field");

  let flatpickrInstance = flatpickr(dateField, {
    altInput: true,
    altFormat: "J F Y",
  });

  let dateTimeFields = Array.prototype.slice.call(
    el.querySelectorAll(".flatpickr-datetime-field"),
  );

  dateTimeFields.map((fieldElement) => {
    flatpickr(fieldElement, {
      enableTime: true,
      dateFormat: "Z",
      altInput: true,
      altFormat: "J F Y, h:i K",
    });
  });

  // Select 2
  let select2multi = $el.find(".select2-multi");
  select2multi.select2();

  let select2Tags = $el.find(".select2-tags");
  select2Tags.select2({
    tags: true,
    allowClear: true,
    placeholder: "Choose an option or type to add one",
  });

  function formatKeyMessageResult(result) {
    let $result = $(
      '<div class="key-message-result">' +
        '<div class="key-message-result-text">' +
        result.text +
        "</div>" +
        '<div class="key-message-result-description"></div>' +
        '<div class="key-message-result-starts-at"></div>' +
        '<div class="key-message-result-ends-at"></div>' +
        "</div>",
    );

    if (result.description) {
      $result.find(".key-message-result-description").text(result.description);
    }

    if (result.display_starts_at) {
      $result
        .find(".key-message-result-starts-at")
        .text("Starts: " + result.display_starts_at);
    }

    if (result.display_ends_at) {
      $result
        .find(".key-message-result-ends-at")
        .text("Ends: " + result.display_ends_at);
    }

    return $result;
  }

  let select2keyMessages = $el.find(".select2-key-messages");
  select2keyMessages.select2({
    multiple: true,
    templateResult: formatKeyMessageResult,
    ajax: {
      url: props.key_messages_url,
      dataType: "json",
      data: function (params) {
        var query = {};

        var campaignSelector = document.querySelector(
          "#coverage_item_campaign_id",
        );

        if (campaignSelector.selectedIndex !== 0) {
          query.campaign_id = campaignSelector.selectedOptions[0].value;
        }

        return query;
      },
      processResults: function (data, params) {
        return {
          results: data,
        };
      },
    },
  });

  props.selected_key_messages.map(function (key_message) {
    var option = new Option(key_message.text, key_message.id, true, true);
    select2keyMessages.append(option).trigger("change");
  });

  let select2mandatories = $el.find(".select2-mandatories");
  select2mandatories.select2({
    multiple: true,
    templateResult: formatKeyMessageResult,
    ajax: {
      url: props.mandatories_url,
      dataType: "json",
      data: function (params) {
        var query = {};

        var campaignSelector = document.querySelector(
          "#coverage_item_campaign_id",
        );

        if (campaignSelector.selectedIndex !== 0) {
          query.campaign_id = campaignSelector.selectedOptions[0].value;
        }

        return query;
      },
      processResults: function (data, params) {
        return {
          results: data,
        };
      },
    },
  });

  props.selected_mandatories.map(function (mandatory) {
    var option = new Option(mandatory.text, mandatory.id, true, true);
    select2mandatories.append(option).trigger("change");
  });

  function displayCoverageTypeSection(sectionName) {
    // Can't use find for this
    $("[data-section-coverage-type]").hide(200);

    if (sectionName.length > 0) {
      let selector = '[data-section-coverage-type="' + sectionName + '"]';
      $(selector).show(200);
    }
  }

  function onCoverageTypeChange() {
    let selectedCoverageType = $("#coverage-type-select")
      .find(":selected")
      .text();
    displayCoverageTypeSection(selectedCoverageType);
  }

  $("#coverage-type-select").on("change", onCoverageTypeChange);

  function updateMediaTiersTable(campaignId) {
    let url = props.media_tiers_url;

    if (campaignId) {
      url = url + "?campaign_id=" + campaignId;
    }

    $.get(url, function (result) {
      $(".media-tier-guide").html(result);
    });
  }

  function onCampaignChange() {
    let campaignSelector = $(".campaign-select");
    updateMediaTiersTable(campaignSelector.val());
  }

  $(".campaign-select").on("change", onCampaignChange);
  updateMediaTiersTable(props.campaign_id);

  function onBacklinkIncludedChange() {
    let backlinkIncludedSelector = $(
      "select[name='coverage_item[backlink_included]",
    );
    let backlinkFieldsWrapperEl = $("#backlink-fields-wrapper");

    if (backlinkIncludedSelector.val().match(/Yes/)) {
      backlinkFieldsWrapperEl.show();
    } else {
      backlinkFieldsWrapperEl.hide();
    }
  }

  $("select[name='coverage_item[backlink_included]").on(
    "change",
    onBacklinkIncludedChange,
  );

  function onSyndicatedChange() {
    const syndicatedEl = $("input[name='coverage_item[syndicated]", el);
    const syndicationFieldsWrapperEl = $("#syndication-fields-wrapper", el);

    if (syndicatedEl.is(":checked")) {
      syndicationFieldsWrapperEl.show();
    } else {
      syndicationFieldsWrapperEl.hide();
    }
  }

  $("input[name='coverage_item[syndicated]", el).on(
    "change",
    onSyndicatedChange,
  );

  function destroy() {
    $("#coverage-type-select").off("change", onCoverageTypeChange);
    select2Tags.select2("destroy");
    select2multi.select2("destroy");
    flatpickrInstance.destroy();
  }

  return {
    destroy: destroy,
  };
}
