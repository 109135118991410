import { Turbo } from "@hotwired/turbo-rails";
import flatpickr from "flatpickr";

export default function reportingPeriodModal(el, props) {
  let $el = $(el);

  const startDateInput = el.querySelector("#startDateInput");
  const endDateInput = el.querySelector("#endDateInput");
  const queryStringInput = el.querySelector("#queryStringInput");
  const submitButton = el.querySelector("#reportingPeriodSubmit");

  let flatpickrStartInstance = flatpickr(startDateInput, {
    altInput: true,
    altFormat: "J F Y",
  });
  let flatpickrEndInstance = flatpickr(endDateInput, {
    altInput: true,
    altFormat: "J F Y",
  });

  function onSubmit(e) {
    e.preventDefault();

    if (startDateInput.value && endDateInput.value) {
      $el.modal("hide");

      let newLocation;

      if (queryStringInput.value) {
        newLocation = `${window.location.pathname}?${queryStringInput.value}&period_start=${startDateInput.value}&period_end=${endDateInput.value}`;
      } else {
        newLocation = `${window.location.pathname}?period_start=${startDateInput.value}&period_end=${endDateInput.value}`;
      }

      Turbo.visit(newLocation);
    }
  }

  submitButton.addEventListener("click", onSubmit);

  function destroy() {
    submitButton.removeEventListener("click", onSubmit);
    flatpickrStartInstance.destroy();
    flatpickrEndInstance.destroy();
  }

  return {
    destroy: destroy,
  };
}
